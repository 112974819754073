<template>
  <v-container>
    <v-row>
      <v-col>
        <CreditCardInput></CreditCardInput>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreditCardInput from '../components/stripe/CreditCardInput'
export default {
  name: 'Testy',
  components: { CreditCardInput }

  // eslint-disable-next-line vue/no-unused-components
}
</script>

<style scoped>

</style>
