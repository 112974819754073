<template>
  <div>
    <stripe-element-card
      ref="elementRef"
      :pk="pulishableKey"
      hidePostalCode
      @token="tokenCreated"
    />
    <v-btn @click="submit">Generate token</v-btn>
  </div>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe'
export default {
  name: 'CreditCardInput',
  components: {
    StripeElementCard
  },
  data () {
    return {
      token: null,
      pulishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    }
  },
  method: {
    submit () {
      // this will trigger the process
      this.$refs.elementRef.submit()
    },
    tokenCreated (token) {
      console.log(token)
      // handle the token
      // send it to your server
    }
  }
}
</script>

<style scoped>

</style>
